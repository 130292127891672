.container-dashboard{
    width: 100vw;
    height: 90%;
}
.container-contribution{
    overflow-y: auto;
    width: 25%;
    height: 100%;
    scrollbar-width: thin; 
    scrollbar-color: #303030 #808080; 
    border-radius: 8px;
    border: 1px solid #303030;
}
.container-goal{
    margin: 20px 1%;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1e1f24; 
    border-radius: 8px;

}
.container-information{
    display: flex;
    justify-content: space-between;
    height: 75%;
    padding-left: 1%;
    padding-right: 1%;
}
.btn-go-data{
    border: 0;
    padding: 1%;
    font-weight: bold;
    width: 25%;
    height: fit-content;
    cursor: pointer;
    background-color: #2986ff;
    color: #fff;
    border-radius: 4px;

}

.welcome{
    font-size: 24px;
    font-weight: bold;
    color: #2986ff;
    
    margin-top: 20px;
}

.container-latest{
    width: 25%;
    background-color: #1e1f24;
    border-radius: 8px;
    border: 1px solid #303030;
    color: #fff,
}
@media only screen and (max-width: 600px) {
    .container-dashboard{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
.container-information{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.container-contribution{
    width: 95%;
    order: 2;
}
.container-latest{
    width: 95%;
    order: 3;
}

.btn-go-data{
    width: 95%;
    height: 10%;
    order: 1;
}

.container-goal{
    width: 95%;
}

}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 992px) {

}

@media only screen and (min-width: 1200px) {}