.container-nav{
    display: flex;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    align-items: center;
    height: 5%;
    background-color: #1e1f24;
    color: #2986ff;

    border-bottom: 1px solid #ededed;
}
.title{
    font-weight: bold;
    font-size: 24px;
    user-select: none;
    cursor: pointer;
}
.download-all-data{
    display: none;
    background-color: #2986ff;
    border: 0;
    padding: 10px;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
}
.download-all-data a{
    text-decoration: none;
    color: #ffffff;
}
