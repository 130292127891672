.container-check{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 90%;
}

.main-check{
    display: flex;
    height: 50%;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2%;
}

.input-numbers{
    min-height: 50%;
    height: 50%;
    max-height: 200%;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
}
.add-btn{
    width: 50%;
    border: 0;
    border-radius: 4px;
    padding: 10px;
    color: white;
    background-color: #2b3d4f;
}